@import "../../style.scss";

.update {
  @include themify($themes) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;

    .wrapper {
      margin: auto;
      width: 40%;
      height: 70%;
      background-color: themed("bg");
      padding: 50px;
      overflow: scroll;
      border-radius: 10px;
      z-index: 999;
      display: flex;
      flex-direction: column;
      gap: 20px;
      -webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
      -moz-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
      box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
      position: relative;
      
      @include mobile {
        width: 100%;
        // height: 100%;
      }

      h1 {
        color: lightgrey;
      
        @include mobile {
         font-size: 20px;
        }
      
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .files {
          display: flex;
          flex-wrap: wrap;
          gap: 50px;
        }

        label {
          display: flex;
          flex-direction: column;
          gap: 10px;
          color: gray;
          font-size: 14px;

          .imgContainer {
            position: relative;
            img {
              width: 100px;
              height: 100px;
              object-fit: cover;
            }

            .icon {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              font-size: 30px;
              color: lightgray;
              cursor: pointer;
            }
          }
        }

        input {
          padding: 5px;
          border: none;
          border-bottom: 1px solid themed("border");
          color: gray;
          background-color: transparent;
        }

        select{
          padding: 5px;
          border: none;
          border-bottom: 1px solid themed("border");
          color: gray;
          background-color: transparent;
        }

        button {
          border: none;
          padding: 10px;
          cursor: pointer;
          color: white;
          background-color: #5271ff;
        }
      }

      .close {
        position: absolute;
        top: 10px;
        right: 20px;
        border: none;
        background-color: #f0544f;
        padding: 5px;
        cursor: pointer;
        color: white;
      }
    }
  }
}
