@import "../../style.scss";

select{
  padding: 10px 0px;
  margin-bottom: 10px;
  font-size: 18px;
  width: 70%;
  border: none;
  border-bottom: 0px solid #ccc;
  outline: none;
  align-items: center;
}
.files {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}
label {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: gray;
  font-size: 14px;



.imgContainer {
  position: relative;
  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  .icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 30px;
    color: lightgray;
    cursor: pointer;
  }
}
}

.share {
  @include themify($themes) {
    -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    border-radius: 20px;
    background-color: themed("bg");
    color: themed("textColor");
    margin-bottom: 20px;

    table{
      width: 100%;
      padding: 20px;
      thead{
         th{
          padding: 10px;
         }
      }
      tbody{
        td{
          padding: 15px 10px;
          text-align: center;
          a{
            padding: 5px;
            font-size: 12px;
            background-color: #5271ff;
            border-radius: 6px;
            color: white;
            text-decoration: none;
          }
        }
        
      }
    }

    .container {
      padding: 20px;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;
          flex: 3;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
          }

          input {
            border: none;
            outline: none;
            padding: 20px 10px;
            background-color: transparent;
            width: 60%;
            color: themed("textColor");
          }
          
        }
        .right {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          .file {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 0px;
          }
        }
      }

      hr {
        margin: 20px 0px;
        border: none;
        height: 0.5px;
        background-color: themed("border");
      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          gap: 20px;

          .item {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            img {
              height: 20px;
            }

            span {
              font-size: 12px;
              color: gray;
            }
          }
        }
        .right {
          button {
            border: none;
            padding: 5px;
            color: white;
            cursor: pointer;
            background-color: #5271ff;
            border-radius: 3px;
          }
        }
      }
    }
  }
}


.profile {
  @include themify($themes) {
    background-color: themed("bgSoft");

    .images {
      width: 100%;
      height: 200px;
      position: relative;
    }
  }
}