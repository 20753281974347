@import "../../style.scss";

.home{
  @include themify($themes) {
    padding: 20px 70px;
    background-color: themed("bgSoft");
    min-height: 100vh;

    @include mobile {
      padding: 10px;
    }

    @include tablet{
      padding: 20px;
    }
  }
.group_id_box{
  padding: 10px;
  display: block;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0 16px rgba(0,0,0,0.15);
  u{
    cursor: pointer;
  }
}
}



.reponsive-mobile{
  display: flex;
  @include mobile{
    display: block;
    .rightBar{
      display: block;
    }
    .group_id_box{
      word-wrap: break-word;
    }
  
    .leftBar{
      display: block;
      position: fixed;
      background-color: white;
      width: 100%;
      height: 100vh;
      z-index: 99999;
      left: -4000px;
      transition: 0.3s;
    }
  
    .leftBar.open{
      left: 0px;
    }
  }
 
}


